import { handleActions } from "redux-actions";

import * as appActions from "./appActions";

const initialState = {
  launchTime: new Date(),
};

const reducer = handleActions(
  {
    [appActions.init]: () => initialState,
  },
  initialState,
);

export default reducer;
