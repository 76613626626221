import { setContext } from "@apollo/client/link/context";

import constants from "../utils/constants";

// eslint-disable-next-line no-unused-vars
export default setContext((_, { headers }) => {
  const refreshToken = localStorage.getItem(constants.REFRESH_TOKEN);
  const xClientUUID = localStorage.getItem(constants.X_CLIENT_UUID);
  return {
    headers: {
      "X-Client-UUID": xClientUUID,
      "X-Client-Name": `${constants.X_CLIENT_NAME}-${
        import.meta.env.VITE_APP_RELEASE_VERSION
      }`,
      Authorization: `Bearer ${refreshToken}`,
    },
  };
});
