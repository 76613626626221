import constants from "@/utils/constants";

export function getHasEditPermissions() {
  const accountEmail = localStorage.getItem(constants.ACCOUNT_EMAIL);
  return accountEmail?.endsWith("@atlas.kitchen");
}

// this is currently used for edit outlet
export function getHasLimitedEditPermissions() {
  const accountEmail = localStorage.getItem(constants.ACCOUNT_EMAIL);
  return (
    accountEmail?.endsWith("@thecoconutclub.sg") ||
    accountEmail?.endsWith("@theprovidore.com")
  );
}
