import { RestLink } from "apollo-link-rest";
import download from "downloadjs";

export default new RestLink({
  uri: `${import.meta.env.VITE_ACCOUNT_GRAPH_URL}/v1`,
  responseTransformer: async (response) => {
    const contentType = Object.fromEntries(response.headers)["content-type"];
    // Note: Assuming this is always the menu download request
    switch (contentType) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return response.blob().then((blob) => {
          const responseURL = new URL(response.url);
          const menuIdentifier =
            responseURL.searchParams.get("menu_identifier");
          download(
            blob,
            `menu_${menuIdentifier}_${Date.now()}.xlsx`,
            contentType,
          );
        });
      default:
        return response.json().then((json) => json);
    }
  },
});
