import { gql } from "@apollo/client";

export const CHANNEL_LINKS_QUERY = gql`
  query GetChannelLinks {
    channelLinks {
      id
      channel {
        id
        label
        type
        subType
      }
      outlet {
        id
        label
      }
      brand {
        id
        label
      }
      menus {
        id
        label
      }
    }
  }
`;

const CHANNEL_LINK_FIELDS = gql`
  fragment ChannelLinkFields on ChannelLink {
    id
    outlet {
      id
      label
    }
    menus {
      id
      label
    }
  }
`;

export const SCAN_TO_ORDER_CHANNEL_LINKS_QUERY = gql`
  query GetScanToOrderChannelLinks {
    scanToOrderChannelLinks {
      ...ChannelLinkFields
      channel {
        id
        label
      }
    }
  }
  ${CHANNEL_LINK_FIELDS}
`;

export const KIOSK_CHANNEL_LINKS_QUERY = gql`
  query GetKioskChannelLinks {
    kioskChannelLinks {
      ...ChannelLinkFields
      channel {
        id
        label
      }
    }
  }
  ${CHANNEL_LINK_FIELDS}
`;
