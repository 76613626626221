import { ApolloLink, HttpLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

const modifiedOperation = (operation) => {
  const graph = operation.getContext().graph;
  const uri = `${import.meta.env.VITE_ACCOUNT_GRAPH_URL}/v1/${graph}/graphql`;
  if (operation.getContext().hasUpload) {
    return createUploadLink({ uri }).request(operation);
  }
  return new HttpLink({ uri }).request(operation);
};

// https://github.com/apollographql/apollo-client/blob/1dbff98573a782f8bf77ab6c21a57d81c91e02ee/src/link/core/ApolloLink.ts#L47
// https://www.apollographql.com/docs/react/api/link/introduction/#directional-composition
// ApolloLink.split chain's execution to branch, depending on the details of the operation being performed.
// This is a hack to always execute next and handle the dynamic routing based on operation.

// For this to work, the operations (eg; useQuery, useMutation) must always send graph name as the context
// useQuery(QUERY_NAME, {
//    context: { graph: "accounts" },
// }
export default ApolloLink.split(
  () => true,
  (operation) => modifiedOperation(operation),
);
