import { gql } from "@apollo/client";

export const CREATE_PAYMENT_PROCESSOR_MUTATION = gql`
  mutation CreatePaymentProcessor(
    $paymentProcessor: PaymentProcessorAttributes!
  ) {
    createPaymentProcessor(input: $paymentProcessor) {
      id
      label
      apiCredentialId
      storeProcessorId
      outletPaymentProcessors {
        id
        outlet {
          id
          label
        }
      }
    }
  }
`;
