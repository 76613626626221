import React, { useEffect, useState, useContext } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { message } from "@/common/Message";
import { AccountContext } from "@/components/Account/Account";
import ConfigContext from "@/components/Config/configContext";
import { CREATE_PAYMENT_PROCESSOR_MUTATION } from "@/graphql/mutations/createPaymentProcessor";
import { PAYMENT_PROCESSORS_QUERY } from "@/graphql/queries/getPaymentProcessors";
import { getHasEditPermissions } from "@/utils/accessControl";
import constants from "@/utils/constants";

const Button = loadable(() => import("@/common/Button"));
const Input = loadable(() => import("@/common/Input"));
const Layout = loadable(() => import("@/common/Layout"));
const PaymentProcessorFormModal = loadable(() =>
  import("./PaymentProcessorFormModal"),
);
const PaymentProcessorsTable = loadable(() =>
  import("./PaymentProcessorsTable"),
);
const Spin = loadable(() => import("@/common/Spin"));

export default function PaymentProcessors() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const hasEditPermissions = getHasEditPermissions();
  const { configQuery } = useContext(ConfigContext);
  const { accountIsAdmin } = useContext(AccountContext);
  const isPermissionsEnabled = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_PERMISSIONS_BASED_ACCESS,
  );
  const hasAccessToPaymentProcessorEdit = isPermissionsEnabled
    ? configQuery?.config?.permissions?.settings?.payment_processors?.write
    : hasEditPermissions || accountIsAdmin;
  const hasAccessToPaymentProcessorCreate = isPermissionsEnabled
    ? configQuery?.config?.permissions?.settings?.payment_processors?.create
    : hasEditPermissions;

  const [form, setForm] = useState({});
  const [paymentProcessors, setPaymentProcessors] = useState([]);
  const [createPaymentProcessor, createPaymentProcessorResponse] = useMutation(
    CREATE_PAYMENT_PROCESSOR_MUTATION,
    {
      context: { graph: "restaurants" },
      errorPolicy: "all",
      onCompleted: (data) => {
        if (data?.createPaymentProcessor) {
          message.success(t("paymentProcessors.form.success.create"));
          navigate(
            `/payment-processors/${data.createPaymentProcessor.id}/edit`,
          );
        }
      },
      refetchQueries: [
        {
          query: PAYMENT_PROCESSORS_QUERY,
          context: { graph: "restaurants" },
        },
      ],
      // wait for refetch to complete before considering mutation complete
      awaitRefetchQueries: true,
    },
  );

  const paymentProcessorsQuery = useQuery(PAYMENT_PROCESSORS_QUERY, {
    context: { graph: "restaurants" },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data?.paymentProcessors) {
        setPaymentProcessors(data.paymentProcessors);
      }
    },
  });

  function getFilteredData() {
    let filteredData = paymentProcessors;
    if (searchTerm) {
      filteredData = filteredData.filter((paymentProcessor) =>
        [
          paymentProcessor.id.toString(),
          paymentProcessor.label,
          paymentProcessor.storeProcessorId,
        ].some((prop) =>
          prop?.toLowerCase()?.includes(searchTerm.toLowerCase()),
        ),
      );
    }
    return filteredData;
  }

  useEffect(() => {
    if (paymentProcessorsQuery?.error?.message) {
      message.error(paymentProcessorsQuery?.error?.message);
    }
  }, [paymentProcessorsQuery?.error]);

  return (
    <Layout className="outlets" pageTitle={t("paymentProcessors.header")}>
      <div className="p-4 mb-4 rounded-md bg-yellow-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="w-5 h-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              {t("common.contactAccountManagerHeader")}
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("common.contactAccountManagerDescription"),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full">
        <Input
          className="flex-grow mr-4"
          type="search"
          placeholder={t("common.search.placeholder")}
          leftIcon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-400" />}
          style={{ width: 295 }}
          onChange={(event) => setSearchTerm(event.target.value)}
        />
        {hasAccessToPaymentProcessorCreate && (
          <Button
            className="min-w-[160px]"
            type="primary"
            onClick={() => navigate("/payment-processors/new")}
          >
            {t("paymentProcessors.actions.add")}
          </Button>
        )}
      </div>
      <Spin spinning={paymentProcessorsQuery?.loading}>
        <div className="mt-4">
          <PaymentProcessorsTable
            paymentProcessors={getFilteredData()}
            editLink={
              hasAccessToPaymentProcessorEdit
                ? (paymentProcessor) =>
                    `/payment-processors/${paymentProcessor.id}/edit`
                : null
            }
          />
        </div>
        <PaymentProcessorFormModal
          form={form}
          setForm={setForm}
          onSave={() => {
            let finalForm = {
              paymentProcessor: {
                ...form,
                outletPaymentProcessors:
                  form.outletPaymentProcessors?.map(
                    (outletPaymentProcessor) => ({
                      paymentProcessorId: null,
                      outletId: parseInt(outletPaymentProcessor.outlet.id),
                      _destroy: outletPaymentProcessor._destroy ?? false,
                    }),
                  ) || [],
              },
            };
            createPaymentProcessor({
              variables: finalForm,
            });
          }}
          loading={createPaymentProcessorResponse.loading}
          error={createPaymentProcessorResponse.error}
        />
      </Spin>
    </Layout>
  );
}
