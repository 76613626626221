import React from "react";

import { PlusIcon } from "@heroicons/react/24/outline";
import PropTypes from "prop-types";

Button.propTypes = {
  /** `primary`, `secondary`, `default`, or `text`. */
  type: PropTypes.string,
  /** `thin`, `xs`, `sm`, `md`, `lg`, or `xl`. */
  size: PropTypes.string,
  /** The HTML type property for a button, usually either `button` or `submit`. */
  htmlType: PropTypes.string,
  /** The HTML disabled property for a button */
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Leading icon in SVG/JSX format. See https://heroicons.com/ for list of icons. */
  leftIcon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
  ]),
  /** Trailing icon in SVG/JSX format. See https://heroicons.com/ for list of icons. */
  rightIcon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  className: PropTypes.string,
  /** Class override for border radius */
  buttonHeightClass: PropTypes.string,
  roundedClass: PropTypes.string,
  paddingX: PropTypes.string,
  /** Any other properties or events that will be passed on to the button instance. */
  rest: PropTypes.any,
  as: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

Button.defaultProps = {
  type: "default",
  size: "md",
  htmlType: "button",
  disabled: false,
  as: "button",
  buttonHeightClass: "h-12",
};

export default function Button({
  type,
  size,
  htmlType,
  disabled,
  leftIcon,
  rightIcon,
  children,
  className,
  roundedClass,
  as,
  buttonHeightClass,
  paddingX,
  ...rest
}) {
  let buttonKlass =
    "inline-flex items-center justify-center font-normal shadow-sm";

  if (size !== "tag") {
    buttonKlass += " focus:outline-none focus:ring-2 focus:ring-offset-2";
  }

  if (
    !["danger", "danger-secondary", "danger-outline", "success"].includes(type)
  ) {
    buttonKlass += " focus:ring-indigo-500";
  }

  if (size !== "tag") {
    buttonKlass += ` ${buttonHeightClass}`;
  }

  switch (type) {
    case "primary":
      buttonKlass +=
        " border-transparent text-white bg-atlas-primary-pink hover:bg-atlas-pink-dark";
      break;
    case "primary-outline":
      buttonKlass += ` ${
        disabled
          ? "border-atlas-pink-dark text-atlas-pink-dark"
          : "border-atlas-primary-pink text-atlas-primary-pink"
      } bg-transparent border focus:border-atlas-pink-dark focus:ring-transparent`;
      break;
    case "secondary":
      buttonKlass += ` border-transparent ${
        disabled ? "text-indigo-300" : "text-indigo-700"
      } bg-indigo-100 hover:bg-indigo-200`;
      break;
    case "success":
      buttonKlass +=
        " border-transparent text-white bg-green-500 hover:bg-green-600 focus:ring-green-500";
      break;
    case "danger":
      buttonKlass +=
        " border-transparent text-white bg-red-600 hover:bg-red-700 focus:ring-red-600";
      break;
    case "danger-secondary":
      buttonKlass += ` border-transparent ${
        disabled ? "text-red-300" : "text-red-800"
      } bg-red-100 hover:bg-red-200 focus:ring-red-600`;
      break;
    case "danger-outline":
      buttonKlass += ` bg-transparent ${
        disabled ? "border-red-300 text-red-300" : "border-red-600 text-red-600"
      } hover:bg-red-50 focus:ring-red-600 border`;
      break;
    case "warning":
      buttonKlass +=
        " border-transparent text-white bg-yellow-600 hover:bg-yellow-700";
      break;
    case "pos":
      buttonKlass +=
        " border-transparent text-gray-50 bg-gray-800 hover:text-gray-200 hover:bg-gray-900 focus:ring-gray-900";
      break;
    case "default":
      buttonKlass += ` border-gray-300 ${
        disabled ? "text-gray-300" : "text-gray-700"
      } bg-white hover:bg-gray-50 border `; // to swap to bg-atlas-navy-3 hover:bg-atlas-navy-4 eventually
      break;
    case "transparent":
      buttonKlass += ` border-gray-300 ${
        disabled ? "text-gray-300" : "text-gray-700"
      } border `; // to swap to bg-atlas-navy-3 hover:bg-atlas-navy-4 eventually
      break;
    case "white":
      buttonKlass += ` border-gray-300 ${
        disabled ? "text-gray-300" : "text-gray-700"
      } bg-white hover:bg-gray-50 border`;
      break;
    case "text":
      buttonKlass += ` border-transparent text-indigo-700 bg-transparent ${
        disabled ? "" : "hover:text-indigo-900"
      } underline shadow-none`;
      break;
  }

  let defaultRounded = "rounded-md";

  switch (size) {
    case "tag":
      buttonKlass += " text-base ";
      defaultRounded = "rounded-full";
      if (type !== "text") buttonKlass += paddingX ?? "px-3 py-1.5";
      break;
    case "thin":
      buttonKlass += " py-1 text-sm ";
      defaultRounded = "rounded";
      if (type !== "text") buttonKlass += paddingX ?? "px-3";
      break;
    case "xs":
      buttonKlass += " py-1.5 text-sm ";
      defaultRounded = "rounded";
      if (type !== "text") buttonKlass += paddingX ?? "px-2.5";
      break;
    case "sm":
      buttonKlass += " py-2 text-base leading-4 ";
      if (type !== "text") buttonKlass += paddingX ?? "px-3";
      break;
    case "md":
      buttonKlass += " py-2 text-base ";
      if (type !== "text") buttonKlass += paddingX ?? "px-4";
      break;
    case "lg":
      buttonKlass += " py-2 text-base ";
      if (type !== "text") buttonKlass += paddingX ?? "px-4";
      break;
    case "xl":
      buttonKlass += " py-3 text-base ";
      if (type !== "text") buttonKlass += paddingX ?? "px-6";
      break;
  }

  buttonKlass += ` ${roundedClass || defaultRounded}`;

  if (disabled) {
    switch (type) {
      case "primary-outline":
      case "secondary":
      case "danger-secondary":
      case "danger-outline":
        // colors handled above
        buttonKlass += " pointer-events-none";
        break;
      default:
        buttonKlass += " text-gray-300 pointer-events-none";
    }
  }

  if (className) {
    buttonKlass += ` ${className}`;
  }

  let leftIconKlass;
  let rightIconKlass;
  if (leftIcon || rightIcon) {
    switch (size) {
      case "xs":
      case "sm":
        if (leftIcon) leftIconKlass = "-ml-0.5 mr-2 h-4 w-4";
        if (rightIcon) rightIconKlass = "ml-2 -mr-0.5 h-4 w-4";
        break;
      case "md":
        if (leftIcon) leftIconKlass = "-ml-1 mr-2 h-5 w-5";
        if (rightIcon) rightIconKlass = "ml-2 -mr-1 h-5 w-5";
        break;
      case "lg":
      case "xl":
        if (leftIcon) leftIconKlass = "-ml-1 mr-3 h-5 w-5";
        if (rightIcon) rightIconKlass = "ml-3 -mr-1 h-5 w-5";
        break;
    }
  }

  function parseIcon(icon) {
    switch (icon) {
      case "plus":
        return <PlusIcon />;
      default:
        return icon;
    }
  }

  const Component = as;

  return (
    <Component
      type={htmlType}
      className={buttonKlass}
      disabled={disabled}
      data-testid="button"
      {...rest}
    >
      {leftIcon && <div className={leftIconKlass}>{parseIcon(leftIcon)}</div>}
      {children}
      {rightIcon && (
        <div className={rightIconKlass}>{parseIcon(rightIcon)}</div>
      )}
    </Component>
  );
}
