export default function handleSafariFormValidation(form, t) {
  const requiredElements = Array.from(form.elements).filter(
    (element) => element.required,
  );
  requiredElements.forEach((element) => {
    // reattach input and change listeners
    element.removeEventListener("input", onChange);
    element.removeEventListener("change", onChange);

    element.addEventListener("input", onChange);
    element.addEventListener("change", onChange);
  });
  const invalidElements = requiredElements.filter((element) => !element.value);
  invalidElements.forEach((element) => setInvalidClasses(element, true));

  setTimeout(() => {
    alert(t("common.formValidationErrors"));
  }, 100);
}

function onChange(event) {
  setInvalidClasses(event.target, !event.target.value);
}

function setInvalidClasses(element, isInvalid) {
  const defaultClasses = [
    "focus:ring-indigo-500",
    "focus:border-indigo-500",
    "border-gray-300",
  ];
  const errorClasses = [
    "focus:ring-red-500",
    "focus:border-red-500",
    "border-red-300",
    "bg-red-100",
  ];
  if (isInvalid) {
    defaultClasses.forEach((klass) => element.classList.remove(klass));
    errorClasses.forEach((klass) => element.classList.add(klass));
  } else {
    defaultClasses.forEach((klass) => element.classList.add(klass));
    errorClasses.forEach((klass) => element.classList.remove(klass));
  }
}
