import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import reducers from "./reducers";

function configureStore(initialState = {}) {
  let composeEnhancers = compose;

  // Enable Redux dev tools only in development
  if (import.meta.env.DEV && typeof window === "object") {
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
    }
  }

  const persistConfig = {
    key: "root",
    storage,
    whitelist: [],
  };

  const persistedReducer = persistReducer(
    persistConfig,
    combineReducers(reducers),
  );

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
}

export default configureStore;
