import { RetryLink } from "@apollo/client/link/retry";

export default new RetryLink({
  delay: {
    initial: 300,
    max: 3000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      if (
        [
          "CompleteKitchenDisplayItem",
          "CompleteAllKitchenDisplayItem",
          "RecallKitchenDisplayItem",
          "GetAccountRoles",
          "GetBrandConfigs",
          "GetConfig",
          "GetMerchantApps",
          "GetOutlets",
        ].includes(operation.operationName)
      ) {
        return true;
      }
      return null;
    },
  },
});
