import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";

import accessTokenAuthLink from "./accessTokenAuthLink";
import errorLink from "./errorLink";
import httpLink from "./httpLink";
import omitKeysLink from "./omitKeysLink";
import restLink from "./restLink";
import retryLink from "./retryLink";

export default () => {
  return new ApolloClient({
    link: ApolloLink.from([
      omitKeysLink,
      errorLink,
      accessTokenAuthLink,
      restLink,
      retryLink,
      httpLink,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        ModifierGroup: {
          keyFields: ["id", "idBreadcrumb"],
        },
        ModifierGroupTypeOptimised: {
          keyFields: ["id", "idBreadcrumb"],
        },
        Modifier: {
          keyFields: ["id", "idBreadcrumb"],
        },
        ModifierTypeOptimised: {
          keyFields: ["id", "idBreadcrumb"],
        },
        FeatureFlip: {
          keyFields: ["key"],
        },
      },
    }),
  });
};
