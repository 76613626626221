import React, { createContext } from "react";

import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

const Layout = loadable(() => import("@/common/Layout"));

export const AccountContext = createContext({});
export const AccountProvider = AccountContext.Provider;

export default function Account() {
  const { t } = useTranslation();

  return (
    <Layout className="account" pageTitle={t("account.header")}>
      <Outlet />
    </Layout>
  );
}
