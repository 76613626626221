import posthog from "posthog-js";

import segment from "@/lib/segment";

const Analytics = {
  page(path, properties = {}) {
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
    segment.page(path, properties);
  },

  track(event, properties = {}) {
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track-link
    segment.track(event, properties);
  },

  identify(userId, traits = {}) {
    // Note: Identify user if PostHog is enabled
    if (import.meta.env.VITE_POSTHOG_KEY) {
      posthog.identify(userId, traits);
    }
    if (import.meta.env.VITE_SEGMENT_WRITE_KEY) {
      // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
      segment.identify(userId, traits);
    }
  },
};

export default Analytics;
