import { ApolloLink } from "@apollo/client";

// remove default timestamps `createdAt` and `updatedAt` from requests
export default new ApolloLink((operation, forward) => {
  // skip when context hasUpload to prevent JSON.stringify from removing file,
  // since the payload will not have createdAt and updatedAt anyway
  if (operation.variables && !operation.getContext().hasUpload) {
    let omitKeys = ["__typename"];
    if (!operation.getContext().hasUpload) {
      omitKeys = ["createdAt", "updatedAt", "__typename"];
    }

    const omitKeyFunc = (key, value) => {
      return omitKeys.includes(key) ? undefined : value;
    };

    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitKeyFunc,
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});
