import { MapPinIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "@/common/Button";

export default function RouteNotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <div className="flex items-center w-full h-full">
        <div className="flex flex-col items-center w-full px-4 mb-12">
          <div className="flex items-center justify-center bg-gray-200 rounded-full w-[90px] h-[90px]">
            <MapPinIcon className="w-12 h-12 text-gray-500" />
          </div>
          <div className="mt-6 text-2xl font-bold">
            {t("common.routeNotFoundHeader")}
          </div>
          <div className="mt-3 text-base text-center">
            {t("common.routeNotFoundContent")}
          </div>
          <div className="flex flex-col gap-4 mt-6">
            <Button type="primary" onClick={handleBackHome}>
              {t("common.backHome")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
