import { setContext } from "@apollo/client/link/context";
import { v4 as uuidv4 } from "uuid";

import constants from "../utils/constants";

// eslint-disable-next-line no-unused-vars
export default setContext((_, { headers }) => {
  const accessToken = localStorage.getItem(constants.ACCESS_TOKEN);
  const pinToken = localStorage.getItem(constants.PIN_TOKEN); // Token used for POS access
  const resetToken = localStorage.getItem(constants.RESET_TOKEN); // Short-lived token used for password reset
  const xMerchantID = localStorage.getItem(constants.X_MERCHANT_ID);
  const xOutletID = localStorage.getItem(constants.X_OUTLET_ID);

  let xClientUUID = localStorage.getItem(constants.X_CLIENT_UUID);
  if (!xClientUUID) {
    xClientUUID = uuidv4();
    localStorage.setItem(constants.X_CLIENT_UUID, xClientUUID);
  }

  return {
    headers: {
      "X-Client-UUID": xClientUUID,
      "X-Client-Name": `${constants.X_CLIENT_NAME}-${
        import.meta.env.VITE_APP_RELEASE_VERSION
      }`,
      ...(xMerchantID ? { "X-Merchant-ID": xMerchantID } : {}),
      ...(xOutletID ? { "X-Outlet-ID": xOutletID } : {}),
      ...(resetToken ?? accessToken // If reset token exists, use that instead
        ? { Authorization: `Bearer ${resetToken ?? accessToken}` }
        : {}),
      ...(pinToken ? { "X-Pin-Token": pinToken } : {}),
      ...headers,
    },
  };
});
