import React, { useMemo } from "react";

import { RocketLaunchIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from "virtual:pwa-register/react";

import Button from "@/common/Button";
import getVersionInfoFromTag from "@/utils/getVersionInfoFromTag";
import useLogout from "@/utils/useLogout";

const OUTDATED_VERSION_ERROR = "Failed to fetch dynamically imported module";

ErrorScreen.propTypes = {
  error: PropTypes.object,
};

export default function ErrorScreen({ error }) {
  const { t } = useTranslation();

  const [logout] = useLogout();

  const version = useMemo(
    () =>
      getVersionInfoFromTag(import.meta.env.VITE_APP_RELEASE_VERSION).version,
    [],
  );

  const isOutdatedVersionError = useMemo(
    () => error?.toString()?.includes(OUTDATED_VERSION_ERROR),
    [error],
  );

  const { updateServiceWorker } = useRegisterSW({
    immediate: true,
  });

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <div className="flex items-center w-full h-full">
        <div className="flex flex-col items-center w-full max-w-screen-md px-4 mx-auto mb-12">
          <div className="flex items-center justify-center bg-gray-200 rounded-full w-[90px] h-[90px]">
            <RocketLaunchIcon className="w-12 h-12 text-gray-500" />
          </div>
          <div className="mt-6 text-2xl font-bold text-center">
            {t("common.genericErrorHeader")}
          </div>
          <div className="mt-3 text-base text-center">
            {isOutdatedVersionError
              ? t("common.outdatedVersionErrorContent")
              : t("common.genericErrorContent")}
          </div>
          {error && (
            <div className="max-w-lg p-2 mt-3 font-mono text-left text-red-500 bg-gray-200 rounded-md">
              {isOutdatedVersionError ? (
                t("common.outdatedVersionErrorAction")
              ) : (
                <>
                  {error.toString()}
                  <br />
                  <br />
                  {`(v${version})`}
                </>
              )}
            </div>
          )}
          <div className="flex flex-col items-center gap-4 mt-6">
            <Button
              type="primary"
              className="w-60"
              onClick={() => {
                if (isOutdatedVersionError) {
                  try {
                    updateServiceWorker(true);
                  } catch (e) {
                    window.location.reload();
                  }
                } else {
                  window.location.reload();
                }
              }}
            >
              {t("navigation.operations.reloadApp")}
            </Button>
            <a href="/">
              <Button type="secondary" className="w-60">
                {t("common.backHome")}
              </Button>
            </a>
            <Button type="secondary" className="w-60" onClick={() => logout()}>
              {t("profileDropdown.options.logout")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
