import { message as antMessage } from "antd";

import Message from "./Message";

export const message = {
  open: (config) => {
    return antMessage.open(modifyContent(config));
  },
  success: (content, duration, onClose) => {
    const config = buildConfig(content, duration, onClose, "success");
    return antMessage.open(modifyContent(config));
  },
  error: (content, duration, onClose) => {
    const config = buildConfig(content, duration, onClose, "error");
    return antMessage.open(modifyContent(config));
  },
  info: (content, duration, onClose) => {
    const config = buildConfig(content, duration, onClose, "info");
    return antMessage.open(modifyContent(config));
  },
  warning: (content, duration, onClose) => {
    const config = buildConfig(content, duration, onClose, "warning");
    return antMessage.open(modifyContent(config));
  },
  warn: (content, duration, onClose) => {
    const config = buildConfig(content, duration, onClose, "warn");
    return antMessage.open(modifyContent(config));
  },
  destroy: (key) => {
    return antMessage.destroy(key);
  },
};

function buildConfig(content, duration, onClose, icon) {
  if (content?.content) {
    return {
      icon,
      ...content,
    };
  }
  return { content, duration, onClose, icon };
}

function modifyContent(content) {
  const modifiedConfig = getModifiedConfig(content?.content ? content : {});
  const modifiedContent = (
    <Message
      messageKey={modifiedConfig.key}
      closable={modifiedConfig.closable}
      icon={modifiedConfig.icon}
    >
      {modifiedConfig.content}
    </Message>
  );
  if (content?.content) {
    return {
      ...modifiedConfig,
      content: modifiedContent,
      // don't pass icon to antMessage
      icon: null,
    };
  }
  return modifiedContent;
}

function getModifiedConfig(config) {
  return {
    ...config,
    // set default duration to 3s
    duration: config.duration || config.duration === 0 ? config.duration : 3,
    // set default key
    key: config.key || new Date().getTime(),
    // set default closable to true
    closable: config.closable !== false,
  };
}
