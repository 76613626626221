import React from "react";

import ReactDOM from "react-dom";
import "./index.css";
import "./lib/i18n";
import smoothscroll from "smoothscroll-polyfill";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Add replaceAll polyfill to solve Android 4.4.4 issue
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === "[object regexp]"
    ) {
      return this.replace(str, newStr);
    }

    // If a string
    return this.replace(new RegExp(str, "g"), newStr);
  };
}

document.addEventListener("DOMContentLoaded", function () {
  const currentUrl = new URL(window.location.href);
  const targetOrigin = "restaurant.atlas.kitchen";
  const redirectUrl = "https://portal.atlas.kitchen";

  if (currentUrl.origin === "https://" + targetOrigin) {
    window.location.replace(redirectUrl);
  }
});
