import { useMutation } from "@apollo/client";

import { LOGOUT_MUTATION } from "@/graphql/mutations/accountLogout";

export default function useLogout() {
  return useMutation(LOGOUT_MUTATION, {
    context: { graph: "accounts" },
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data?.accountLogout?.success) {
        // clear localStorage and go to login page on logout
        localStorage.clear();
        window.location.href = "/login";
      }
    },
  });
}
