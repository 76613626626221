import React, { useEffect, useState } from "react";

import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import posthog from "posthog-js";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { importSegmentScript } from "@/lib/segment";

import Routes from "./Routes";
import ReloadPrompt from "./common/ReloadPrompt";
import ErrorScreen from "./components/ErrorScreen";
import { ConnectionProvider } from "./connectionContext";
import client from "./graphql";
import configureStore from "./redux/configureStore";
import { launchApp } from "./redux/modules/app/appActions";

// Import Segment script on load
importSegmentScript();

// Initialize Sentry
Sentry.init({
  debug: import.meta.env.DEV,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.VITE_APP_RELEASE_VERSION,
  environment: import.meta.env.VITE_SENTRY_ENV,
  integrations: [new Integrations.BrowserTracing()],
});

if (import.meta.env.VITE_POSTHOG_KEY) {
  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: "https://app.posthog.com",
    autocapture: false,
    capture_pageview: false,
  });
}

// Suppress warnings for findDOMNode errors from antd as this is not fixed in the library
// https://github.com/ant-design/ant-design/issues/27921

// eslint-disable-next-line
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (errObj, ...args) => {
  if (
    import.meta.env.DEV &&
    typeof errObj === "string" &&
    args.includes("findDOMNode")
  ) {
    return;
  }
  consoleError(errObj, ...args);
};

export default function App() {
  const [store] = useState(configureStore());
  const [apolloClient] = useState(client);
  const [connected, setConnected] = useState();

  useEffect(() => {
    // Add event listeners to detect network status changes
    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    // Set initial connectivity value
    setConnected(window.navigator.onLine);

    // Remove event listeners on unmount
    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, []);

  const handleNetworkChange = () => {
    setConnected(window.navigator.onLine);
  };

  return (
    <ApolloProvider client={apolloClient}>
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        <ConnectionProvider
          value={{
            connected,
          }}
        >
          <Provider store={store}>
            <PersistGate
              persistor={persistStore(store, {}, () => {
                store.dispatch(launchApp());
              })}
            >
              <BrowserRouter>
                <Routes />
                {/* Reload Prompt */}
                <ReloadPrompt />
              </BrowserRouter>
            </PersistGate>
          </Provider>
        </ConnectionProvider>
      </ErrorBoundary>
    </ApolloProvider>
  );
}
